import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "World Championship Women RR",
      edition: 2021,
      what: "Race data",
      updated: "2021-09-24 14:43:48",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Argentina: {
        teamId: "Argentina",
        teamUciCode: "",
        teamName: "Argentina",
        teamNationCode: "ARG",
      },
      Australia: {
        teamId: "Australia",
        teamUciCode: "",
        teamName: "Australia",
        teamNationCode: "AUS",
      },
      Austria: {
        teamId: "Austria",
        teamUciCode: "",
        teamName: "Austria",
        teamNationCode: "AUT",
      },
      Azerbaijan: {
        teamId: "Azerbaijan",
        teamUciCode: "",
        teamName: "Azerbaijan",
        teamNationCode: "AZE",
      },
      Belarus: {
        teamId: "Belarus",
        teamUciCode: "",
        teamName: "Belarus",
        teamNationCode: "BLR",
      },
      Belgium: {
        teamId: "Belgium",
        teamUciCode: "",
        teamName: "Belgium",
        teamNationCode: "BEL",
      },
      Bermuda: {
        teamId: "Bermuda",
        teamUciCode: "",
        teamName: "Bermuda",
        teamNationCode: "BER",
      },
      Brazil: {
        teamId: "Brazil",
        teamUciCode: "",
        teamName: "Brazil",
        teamNationCode: "BRA",
      },
      Canada: {
        teamId: "Canada",
        teamUciCode: "",
        teamName: "Canada",
        teamNationCode: "CAN",
      },
      Chile: {
        teamId: "Chile",
        teamUciCode: "",
        teamName: "Chile",
        teamNationCode: "CHI",
      },
      Colombia: {
        teamId: "Colombia",
        teamUciCode: "",
        teamName: "Colombia",
        teamNationCode: "COL",
      },
      Croatia: {
        teamId: "Croatia",
        teamUciCode: "",
        teamName: "Croatia",
        teamNationCode: "CRO",
      },
      Cuba: {
        teamId: "Cuba",
        teamUciCode: "",
        teamName: "Cuba",
        teamNationCode: "CUB",
      },
      "Czech Republic": {
        teamId: "Czech Republic",
        teamUciCode: "",
        teamName: "Czech Republic",
        teamNationCode: "CZE",
      },
      Denmark: {
        teamId: "Denmark",
        teamUciCode: "",
        teamName: "Denmark",
        teamNationCode: "DEN",
      },
      Eritrea: {
        teamId: "Eritrea",
        teamUciCode: "",
        teamName: "Eritrea",
        teamNationCode: "ERI",
      },
      Estonia: {
        teamId: "Estonia",
        teamUciCode: "",
        teamName: "Estonia",
        teamNationCode: "EST",
      },
      Ethiopia: {
        teamId: "Ethiopia",
        teamUciCode: "",
        teamName: "Ethiopia",
        teamNationCode: "ETH",
      },
      France: {
        teamId: "France",
        teamUciCode: "",
        teamName: "France",
        teamNationCode: "FRA",
      },
      Germany: {
        teamId: "Germany",
        teamUciCode: "",
        teamName: "Germany",
        teamNationCode: "GER",
      },
      "Great Britain": {
        teamId: "Great Britain",
        teamUciCode: "",
        teamName: "Great Britain",
        teamNationCode: "GBR",
      },
      Greece: {
        teamId: "Greece",
        teamUciCode: "",
        teamName: "Greece",
        teamNationCode: "GRE",
      },
      Hungary: {
        teamId: "Hungary",
        teamUciCode: "",
        teamName: "Hungary",
        teamNationCode: "HUN",
      },
      Iceland: {
        teamId: "Iceland",
        teamUciCode: "",
        teamName: "Iceland",
        teamNationCode: "ISL",
      },
      Indonesia: {
        teamId: "Indonesia",
        teamUciCode: "",
        teamName: "Indonesia",
        teamNationCode: "IDN",
      },
      Iran: {
        teamId: "Iran",
        teamUciCode: "",
        teamName: "Iran",
        teamNationCode: "IRN",
      },
      Ireland: {
        teamId: "Ireland",
        teamUciCode: "",
        teamName: "Ireland",
        teamNationCode: "IRL",
      },
      Israel: {
        teamId: "Israel",
        teamUciCode: "",
        teamName: "Israel",
        teamNationCode: "ISR",
      },
      Italy: {
        teamId: "Italy",
        teamUciCode: "",
        teamName: "Italy",
        teamNationCode: "ITA",
      },
      Jamaica: {
        teamId: "Jamaica",
        teamUciCode: "",
        teamName: "Jamaica",
        teamNationCode: "JAM",
      },
      Japan: {
        teamId: "Japan",
        teamUciCode: "",
        teamName: "Japan",
        teamNationCode: "JPN",
      },
      Kenya: {
        teamId: "Kenya",
        teamUciCode: "",
        teamName: "Kenya",
        teamNationCode: "KEN",
      },
      Latvia: {
        teamId: "Latvia",
        teamUciCode: "",
        teamName: "Latvia",
        teamNationCode: "LAT",
      },
      Lithuania: {
        teamId: "Lithuania",
        teamUciCode: "",
        teamName: "Lithuania",
        teamNationCode: "LTU",
      },
      Luxembourg: {
        teamId: "Luxembourg",
        teamUciCode: "",
        teamName: "Luxembourg",
        teamNationCode: "LUX",
      },
      Mexico: {
        teamId: "Mexico",
        teamUciCode: "",
        teamName: "Mexico",
        teamNationCode: "MEX",
      },
      Netherlands: {
        teamId: "Netherlands",
        teamUciCode: "",
        teamName: "Netherlands",
        teamNationCode: "NED",
      },
      "New Zealand": {
        teamId: "New Zealand",
        teamUciCode: "",
        teamName: "New Zealand",
        teamNationCode: "NZL",
      },
      Norway: {
        teamId: "Norway",
        teamUciCode: "",
        teamName: "Norway",
        teamNationCode: "NOR",
      },
      Pakistan: {
        teamId: "Pakistan",
        teamUciCode: "",
        teamName: "Pakistan",
        teamNationCode: "PAK",
      },
      Paraguay: {
        teamId: "Paraguay",
        teamUciCode: "",
        teamName: "Paraguay",
        teamNationCode: "PAR",
      },
      Poland: {
        teamId: "Poland",
        teamUciCode: "",
        teamName: "Poland",
        teamNationCode: "POL",
      },
      Portugal: {
        teamId: "Portugal",
        teamUciCode: "",
        teamName: "Portugal",
        teamNationCode: "POR",
      },
      Russia: {
        teamId: "Russia",
        teamUciCode: "",
        teamName: "Russia",
        teamNationCode: "RUS",
      },
      Rwanda: {
        teamId: "Rwanda",
        teamUciCode: "",
        teamName: "Rwanda",
        teamNationCode: "RWA",
      },
      Serbia: {
        teamId: "Serbia",
        teamUciCode: "",
        teamName: "Serbia",
        teamNationCode: "SBA",
      },
      Slovakia: {
        teamId: "Slovakia",
        teamUciCode: "",
        teamName: "Slovakia",
        teamNationCode: "SVK",
      },
      Slovenia: {
        teamId: "Slovenia",
        teamUciCode: "",
        teamName: "Slovenia",
        teamNationCode: "SLO",
      },
      "South Africa": {
        teamId: "South Africa",
        teamUciCode: "",
        teamName: "South Africa",
        teamNationCode: "RSA",
      },
      Spain: {
        teamId: "Spain",
        teamUciCode: "",
        teamName: "Spain",
        teamNationCode: "ESP",
      },
      Sweden: {
        teamId: "Sweden",
        teamUciCode: "",
        teamName: "Sweden",
        teamNationCode: "SWE",
      },
      Switzerland: {
        teamId: "Switzerland",
        teamUciCode: "",
        teamName: "Switzerland",
        teamNationCode: "SUI",
      },
      Thailand: {
        teamId: "Thailand",
        teamUciCode: "",
        teamName: "Thailand",
        teamNationCode: "THA",
      },
      "Trinidad & Tobago": {
        teamId: "Trinidad & Tobago",
        teamUciCode: "",
        teamName: "Trinidad & Tobago",
        teamNationCode: "TRI",
      },
      Ukraine: {
        teamId: "Ukraine",
        teamUciCode: "",
        teamName: "Ukraine",
        teamNationCode: "UKR",
      },
      USA: {
        teamId: "USA",
        teamUciCode: "",
        teamName: "USA",
        teamNationCode: "USA",
      },
      Uzbekistan: {
        teamId: "Uzbekistan",
        teamUciCode: "",
        teamName: "Uzbekistan",
        teamNationCode: "UZB",
      },
      Zimbabwe: {
        teamId: "Zimbabwe",
        teamUciCode: "",
        teamName: "Zimbabwe",
        teamNationCode: "ZIM",
      },
    },
    riders: {
      89832: {
        id: 89832,
        startno: 5,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      114393: {
        id: 114393,
        startno: 146,
        firstName: "Diane",
        lastName: "Ingabire",
        nationCode: "RWA",
        nationName: "Rwanda",
        birthDate: "2001-07-17",
        teamName: "Rwanda",
        teamId: "Rwanda",
        stillInTheRace: "Y",
      },
      115242: {
        id: 115242,
        startno: 139,
        firstName: "Yanina",
        lastName: "Kuskova",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "2001-12-11",
        teamName: "Uzbekistan",
        teamId: "Uzbekistan",
        stillInTheRace: "Y",
      },
      103220: {
        id: 103220,
        startno: 166,
        firstName: "Ayan",
        lastName: "Khankishiyeva",
        nationCode: "AZE",
        nationName: "Azerbaijan",
        birthDate: "2001-09-07",
        teamName: "Azerbaijan",
        teamId: "Azerbaijan",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 79,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      93119: {
        id: 93119,
        startno: 1016,
        firstName: "Foroozan",
        lastName: "Abdollahi Arab",
        nationCode: "IRN",
        nationName: "Iran",
        birthDate: "1997-03-18",
        teamName: "Iran",
        teamId: "Iran",
        stillInTheRace: "Y",
      },
      89954: {
        id: 89954,
        startno: 88,
        firstName: "Ingvild",
        lastName: "G\u00e5skjenn",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-01",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      90392: {
        id: 90392,
        startno: 30,
        firstName: "Caroline",
        lastName: "Baur",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-04-17",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      98831: {
        id: 98831,
        startno: 160,
        firstName: "Teniel",
        lastName: "Campbell",
        nationCode: "TRI",
        nationName: "Trinidad & Tobago",
        birthDate: "1997-09-23",
        teamName: "Trinidad & Tobago",
        teamId: "Trinidad & Tobago",
        stillInTheRace: "Y",
      },
      107847: {
        id: 107847,
        startno: 1015,
        firstName: "Caitlin",
        lastName: "Conyers",
        nationCode: "BER",
        nationName: "Bermuda",
        birthDate: "1989-12-31",
        teamName: "Bermuda",
        teamId: "Bermuda",
        stillInTheRace: "Y",
      },
      92496: {
        id: 92496,
        startno: 1014,
        firstName: "Selam Amha",
        lastName: "Gerefiel",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1997-09-03",
        teamName: "Ethiopia",
        teamId: "Ethiopia",
        stillInTheRace: "Y",
      },
      89424: {
        id: 89424,
        startno: 135,
        firstName: "Rotem",
        lastName: "Gafinovitz",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1992-06-09",
        teamName: "Israel",
        teamId: "Israel",
        stillInTheRace: "Y",
      },
      181883: {
        id: 181883,
        startno: 1013,
        firstName: "Asma",
        lastName: "Jan",
        nationCode: "PAK",
        nationName: "Pakistan",
        birthDate: "1976-01-07",
        teamName: "Pakistan",
        teamId: "Pakistan",
        stillInTheRace: "Y",
      },
      96793: {
        id: 96793,
        startno: 103,
        firstName: "Caroline",
        lastName: "Andersson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-07-29",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      104320: {
        id: 104320,
        startno: 124,
        firstName: "Stephanie Nathalie",
        lastName: "Subercaseaux Vergara",
        nationCode: "CHI",
        nationName: "Chile",
        birthDate: "1984-03-23",
        teamName: "Chile",
        teamId: "Chile",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 71,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      96818: {
        id: 96818,
        startno: 14,
        firstName: "Vittoria",
        lastName: "Guazzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-12-26",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      92486: {
        id: 92486,
        startno: 65,
        firstName: "Aurela",
        lastName: "Nerlo",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1998-02-11",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89690: {
        id: 89690,
        startno: 129,
        firstName: "Phetdarin",
        lastName: "Somrat",
        nationCode: "THA",
        nationName: "Thailand",
        birthDate: "1995-06-06",
        teamName: "Thailand",
        teamId: "Thailand",
        stillInTheRace: "Y",
      },
      89758: {
        id: 89758,
        startno: 175,
        firstName: "Maria",
        lastName: "Yapura Plaza",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1998-05-06",
        teamName: "Argentina",
        teamId: "Argentina",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 125,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamName: "Cuba",
        teamId: "Cuba",
        stillInTheRace: "Y",
      },
      89536: {
        id: 89536,
        startno: 23,
        firstName: "Lisa",
        lastName: "Brennauer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-06-08",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      99896: {
        id: 99896,
        startno: 140,
        firstName: "Dana",
        lastName: "Rozlapa",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1979-11-16",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      126514: {
        id: 126514,
        startno: 57,
        firstName: "Tiffany",
        lastName: "Keep",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "2000-10-15",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 39,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      90406: {
        id: 90406,
        startno: 99,
        firstName: "Verena",
        lastName: "Eberhardt",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-12-06",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      89743: {
        id: 89743,
        startno: 21,
        firstName: "Julie",
        lastName: "Leth",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-07-13",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      92495: {
        id: 92495,
        startno: 1012,
        firstName: "Varvara",
        lastName: "Fasoi",
        nationCode: "GRE",
        nationName: "Greece",
        birthDate: "1991-02-02",
        teamName: "Greece",
        teamId: "Greece",
        stillInTheRace: "Y",
      },
      89823: {
        id: 89823,
        startno: 2,
        firstName: "Lucinda",
        lastName: "Brand",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-07-02",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89416: {
        id: 89416,
        startno: 8,
        firstName: "Marianne",
        lastName: "Vos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-05-13",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 92,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      125733: {
        id: 125733,
        startno: 78,
        firstName: "Anna",
        lastName: "Shackley",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-17",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 51,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89870: {
        id: 89870,
        startno: 82,
        firstName: "Eider",
        lastName: "Merino",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-02",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      157459: {
        id: 157459,
        startno: 145,
        firstName: "Megan",
        lastName: "Armitage",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-08-12",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      113731: {
        id: 113731,
        startno: 95,
        firstName: "Henrietta",
        lastName: "Christie",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2002-01-23",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      142735: {
        id: 142735,
        startno: 33,
        firstName: "Nicole",
        lastName: "Koller",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-05-02",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      89407: {
        id: 89407,
        startno: 107,
        firstName: "Karol-Ann",
        lastName: "Canuel",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-04-18",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      89917: {
        id: 89917,
        startno: 67,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 10,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 61,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89527: {
        id: 89527,
        startno: 105,
        firstName: "Hanna",
        lastName: "Nilsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1992-02-16",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      107523: {
        id: 107523,
        startno: 113,
        firstName: "Marcela",
        lastName: "Hernandez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-01-04",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      89408: {
        id: 89408,
        startno: 74,
        firstName: "Lizzie",
        lastName: "Deignan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1988-12-18",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 167,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      91079: {
        id: 91079,
        startno: 156,
        firstName: "Maria",
        lastName: "Martins",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1999-07-09",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      90021: {
        id: 90021,
        startno: 122,
        firstName: "Hanna",
        lastName: "Solovey",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1992-01-31",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      89708: {
        id: 89708,
        startno: 44,
        firstName: "Lauretta",
        lastName: "Hanson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-10-29",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      90103: {
        id: 90103,
        startno: 17,
        firstName: "Trine",
        lastName: "Holmsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-09-04",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89551: {
        id: 89551,
        startno: 24,
        firstName: "Lisa",
        lastName: "Klein",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-07-15",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      89463: {
        id: 89463,
        startno: 119,
        firstName: "Rasa",
        lastName: "Leleivyte",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-07-22",
        teamName: "Lithuania",
        teamId: "Lithuania",
        stillInTheRace: "Y",
      },
      155494: {
        id: 155494,
        startno: 60,
        firstName: "Courteney",
        lastName: "Webb",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1998-12-31",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      115243: {
        id: 115243,
        startno: 138,
        firstName: "Anna",
        lastName: "Kulikova",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "2001-03-19",
        teamName: "Uzbekistan",
        teamId: "Uzbekistan",
        stillInTheRace: "Y",
      },
      91068: {
        id: 91068,
        startno: 102,
        firstName: "Kathrin",
        lastName: "Schweinberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1996-10-29",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      109919: {
        id: 109919,
        startno: 165,
        firstName: "Adyam",
        lastName: "Tesfalem",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1998-01-01",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      110030: {
        id: 110030,
        startno: 1011,
        firstName: "Liontin Evangelina",
        lastName: "Setiawan",
        nationCode: "IDN",
        nationName: "Indonesia",
        birthDate: "1999-07-13",
        teamName: "Indonesia",
        teamId: "Indonesia",
        stillInTheRace: "Y",
      },
      89403: {
        id: 89403,
        startno: 1,
        firstName: "Anna",
        lastName: "van der Breggen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-04-18",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 87,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      89525: {
        id: 89525,
        startno: 151,
        firstName: "Jelena",
        lastName: "Eric",
        nationCode: "SBA",
        nationName: "Serbia",
        birthDate: "1996-01-15",
        teamName: "Serbia",
        teamId: "Serbia",
        stillInTheRace: "Y",
      },
      115808: {
        id: 115808,
        startno: 137,
        firstName: "Shaknoza",
        lastName: "Abdullaeva",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "2001-10-25",
        teamName: "Uzbekistan",
        teamId: "Uzbekistan",
        stillInTheRace: "Y",
      },
      90686: {
        id: 90686,
        startno: 54,
        firstName: "Jesse",
        lastName: "Vandenbulcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-01-17",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      125397: {
        id: 125397,
        startno: 144,
        firstName: "Aidi Gerde",
        lastName: "Tuisk",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "2002-01-03",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      155243: {
        id: 155243,
        startno: 150,
        firstName: "Br\u00edet Krist\u00fd",
        lastName: "Gunnarsd\u00f3ttir",
        nationCode: "ISL",
        nationName: "Iceland",
        birthDate: "1988-08-06",
        teamName: "Iceland",
        teamId: "Iceland",
        stillInTheRace: "Y",
      },
      91960: {
        id: 91960,
        startno: 98,
        firstName: "Ella",
        lastName: "Harris",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-07-18",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      89491: {
        id: 89491,
        startno: 143,
        firstName: "Tereza",
        lastName: "Medvedova",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1996-03-27",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      89512: {
        id: 89512,
        startno: 123,
        firstName: "Paola Andrea",
        lastName: "Mu\u00f1oz Grandon",
        nationCode: "CHI",
        nationName: "Chile",
        birthDate: "1986-04-13",
        teamName: "Chile",
        teamId: "Chile",
        stillInTheRace: "Y",
      },
      89923: {
        id: 89923,
        startno: 70,
        firstName: "Roxane",
        lastName: "Fournier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-11-07",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      89984: {
        id: 89984,
        startno: 13,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 64,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      154835: {
        id: 154835,
        startno: 128,
        firstName: "Kamonrada",
        lastName: "Khaoplot",
        nationCode: "THA",
        nationName: "Thailand",
        birthDate: "2002-09-30",
        teamName: "Thailand",
        teamId: "Thailand",
        stillInTheRace: "Y",
      },
      166980: {
        id: 166980,
        startno: 174,
        firstName: "Luciana",
        lastName: "Roland",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1995-10-08",
        teamName: "Argentina",
        teamId: "Argentina",
        stillInTheRace: "Y",
      },
      102644: {
        id: 102644,
        startno: 1010,
        firstName: "Maja",
        lastName: "Perinovic",
        nationCode: "CRO",
        nationName: "Croatia",
        birthDate: "1999-09-24",
        teamName: "Croatia",
        teamId: "Croatia",
        stillInTheRace: "Y",
      },
      90984: {
        id: 90984,
        startno: 77,
        firstName: "Joscelin",
        lastName: "Lowden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-10-03",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      181884: {
        id: 181884,
        startno: 1009,
        firstName: "Julia",
        lastName: "Mirigu",
        nationCode: "KEN",
        nationName: "Kenya",
        birthDate: "1988-01-12",
        teamName: "Kenya",
        teamId: "Kenya",
        stillInTheRace: "Y",
      },
      92981: {
        id: 92981,
        startno: 56,
        firstName: "Kerry",
        lastName: "Jonker",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-05-21",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89843: {
        id: 89843,
        startno: 38,
        firstName: "Lauren",
        lastName: "Stephens",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1986-12-27",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 48,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      155220: {
        id: 155220,
        startno: 20,
        firstName: "Rebecca",
        lastName: "Koerner",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-22",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 29,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      90976: {
        id: 90976,
        startno: 133,
        firstName: "Lizbeth Yareli",
        lastName: "Salazar Vazquez",
        nationCode: "MEX",
        nationName: "Mexico",
        birthDate: "1996-12-08",
        teamName: "Mexico",
        teamId: "Mexico",
        stillInTheRace: "Y",
      },
      93562: {
        id: 93562,
        startno: 7,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      92091: {
        id: 92091,
        startno: 91,
        firstName: "Urska",
        lastName: "Bravec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-14",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      89554: {
        id: 89554,
        startno: 22,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89986: {
        id: 89986,
        startno: 50,
        firstName: "Kim",
        lastName: "de Baat",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-29",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      91078: {
        id: 91078,
        startno: 81,
        firstName: "Sara",
        lastName: "Martin Martin",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-03-22",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      95966: {
        id: 95966,
        startno: 1008,
        firstName: "Somayeh",
        lastName: "Yazdani",
        nationCode: "IRN",
        nationName: "Iran",
        birthDate: "1990-10-06",
        teamName: "Iran",
        teamId: "Iran",
        stillInTheRace: "Y",
      },
      114768: {
        id: 114768,
        startno: 90,
        firstName: "Anne Dorthe",
        lastName: "Ysland",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2002-04-09",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      91163: {
        id: 91163,
        startno: 32,
        firstName: "Sina",
        lastName: "Frei",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-07-18",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      100663: {
        id: 100663,
        startno: 161,
        firstName: "Thayna",
        lastName: "Araujo",
        nationCode: "BRA",
        nationName: "Brazil",
        birthDate: "1997-01-10",
        teamName: "Brazil",
        teamId: "Brazil",
        stillInTheRace: "Y",
      },
      92491: {
        id: 92491,
        startno: 1007,
        firstName: "Eyeru Tesfoam",
        lastName: "Gebru",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1996-12-10",
        teamName: "Ethiopia",
        teamId: "Ethiopia",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 9,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      92704: {
        id: 92704,
        startno: 172,
        firstName: "Agua Marina",
        lastName: "Espinola",
        nationCode: "PAR",
        nationName: "Paraguay",
        birthDate: "1996-03-31",
        teamName: "Paraguay",
        teamId: "Paraguay",
        stillInTheRace: "Y",
      },
      116798: {
        id: 116798,
        startno: 176,
        firstName: "Nathalie",
        lastName: "Eklund",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1992-01-08",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      98939: {
        id: 98939,
        startno: 112,
        firstName: "Lorena",
        lastName: "Colmenares",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1991-02-15",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      89637: {
        id: 89637,
        startno: 73,
        firstName: "Alice",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-07-17",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      181071: {
        id: 181071,
        startno: 1018,
        firstName: "Liori",
        lastName: "Sharpe",
        nationCode: "JAM",
        nationName: "Jamaica",
        birthDate: "2000-10-17",
        teamName: "Jamaica",
        teamId: "Jamaica",
        stillInTheRace: "Y",
      },
      125405: {
        id: 125405,
        startno: 155,
        firstName: "Daniela",
        lastName: "Campos",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "2002-03-31",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      90072: {
        id: 90072,
        startno: 121,
        firstName: "Valeriya",
        lastName: "Kononenko",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1990-05-14",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      89538: {
        id: 89538,
        startno: 43,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      89404: {
        id: 89404,
        startno: 16,
        firstName: "Amalie",
        lastName: "Dideriksen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-05-24",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89447: {
        id: 89447,
        startno: 26,
        firstName: "Romy",
        lastName: "Kasper",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-05-05",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      99938: {
        id: 99938,
        startno: 118,
        firstName: "Inga",
        lastName: "Cesuliene",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1986-02-14",
        teamName: "Lithuania",
        teamId: "Lithuania",
        stillInTheRace: "Y",
      },
      155492: {
        id: 155492,
        startno: 59,
        firstName: "Hayley",
        lastName: "Preen",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1998-05-28",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89712: {
        id: 89712,
        startno: 41,
        firstName: "Ruth",
        lastName: "Winder",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1993-07-08",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      91067: {
        id: 91067,
        startno: 101,
        firstName: "Christina",
        lastName: "Schweinberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1996-10-29",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      95945: {
        id: 95945,
        startno: 164,
        firstName: "Desiet",
        lastName: "Kidane Tekeste",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "2000-10-10",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      99764: {
        id: 99764,
        startno: 1006,
        firstName: "Priatna Ayustina",
        lastName: "Delia",
        nationCode: "IDN",
        nationName: "Indonesia",
        birthDate: "1997-12-12",
        teamName: "Indonesia",
        teamId: "Indonesia",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 4,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89951: {
        id: 89951,
        startno: 86,
        firstName: "Susanne",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-23",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      89533: {
        id: 89533,
        startno: 94,
        firstName: "Urska",
        lastName: "Zigart",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-04",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      99129: {
        id: 99129,
        startno: 132,
        firstName: "Anastasiya",
        lastName: "Kolesava",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "2000-06-02",
        teamName: "Belarus",
        teamId: "Belarus",
        stillInTheRace: "Y",
      },
      89883: {
        id: 89883,
        startno: 53,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89450: {
        id: 89450,
        startno: 84,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      155277: {
        id: 155277,
        startno: 149,
        firstName: "Elin Bjorg",
        lastName: "Bj\u00f6rnsd\u00f3ttir",
        nationCode: "ISL",
        nationName: "Iceland",
        birthDate: "1985-03-20",
        teamName: "Iceland",
        teamId: "Iceland",
        stillInTheRace: "Y",
      },
      92102: {
        id: 92102,
        startno: 97,
        firstName: "Niamh",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-08-12",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      96857: {
        id: 96857,
        startno: 35,
        firstName: "Noemi",
        lastName: "R\u00fcegg",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-04-19",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 109,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      89921: {
        id: 89921,
        startno: 69,
        firstName: "Eug\u00e9nie",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-05-03",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      89537: {
        id: 89537,
        startno: 12,
        firstName: "Elena",
        lastName: "Cecchini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-25",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      99002: {
        id: 99002,
        startno: 63,
        firstName: "Karolina",
        lastName: "Kumiega",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1999-04-16",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      95978: {
        id: 95978,
        startno: 127,
        firstName: "Chaniporn",
        lastName: "Batriya",
        nationCode: "THA",
        nationName: "Thailand",
        birthDate: "1999-08-12",
        teamName: "Thailand",
        teamId: "Thailand",
        stillInTheRace: "Y",
      },
      89709: {
        id: 89709,
        startno: 115,
        firstName: "Diana",
        lastName: "Penuela",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1986-09-08",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      93655: {
        id: 93655,
        startno: 76,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      126668: {
        id: 126668,
        startno: 1006,
        firstName: "Jennifer Kanini",
        lastName: "Salome",
        nationCode: "KEN",
        nationName: "Kenya",
        birthDate: "1987-01-01",
        teamName: "Kenya",
        teamId: "Kenya",
        stillInTheRace: "Y",
      },
      127642: {
        id: 127642,
        startno: 55,
        firstName: "Frances",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "2001-01-10",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 37,
        firstName: "Coryn",
        lastName: "Rivera",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89432: {
        id: 89432,
        startno: 47,
        firstName: "Sarah",
        lastName: "Roy",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-02-27",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      92093: {
        id: 92093,
        startno: 19,
        firstName: "Emma",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-07-26",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      89542: {
        id: 89542,
        startno: 28,
        firstName: "Mieke",
        lastName: "Kr\u00f6ger",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-07-18",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      89410: {
        id: 89410,
        startno: 117,
        firstName: "Christine",
        lastName: "Majerus",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-02-25",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 6,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      95949: {
        id: 95949,
        startno: 147,
        firstName: "Valantine",
        lastName: "Nzayisenga",
        nationCode: "RWA",
        nationName: "Rwanda",
        birthDate: "2000-03-15",
        teamName: "Rwanda",
        teamId: "Rwanda",
        stillInTheRace: "Y",
      },
      126597: {
        id: 126597,
        startno: 1005,
        firstName: "Skye Janine",
        lastName: "Davidson",
        nationCode: "ZIM",
        nationName: "Zimbabwe",
        birthDate: "1997-11-14",
        teamName: "Zimbabwe",
        teamId: "Zimbabwe",
        stillInTheRace: "Y",
      },
      96695: {
        id: 96695,
        startno: 49,
        firstName: "Shari",
        lastName: "Bossuyt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-09-05",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89597: {
        id: 89597,
        startno: 80,
        firstName: "Sheyla",
        lastName: "Gutierrez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-01-01",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      92087: {
        id: 92087,
        startno: 1004,
        firstName: "Mandana",
        lastName: "Dehghan",
        nationCode: "IRN",
        nationName: "Iran",
        birthDate: "1991-02-26",
        teamName: "Iran",
        teamId: "Iran",
        stillInTheRace: "Y",
      },
      89961: {
        id: 89961,
        startno: 89,
        firstName: "Emilie",
        lastName: "Moberg",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1991-07-12",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 31,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      89534: {
        id: 89534,
        startno: 131,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-02-06",
        teamName: "Belarus",
        teamId: "Belarus",
        stillInTheRace: "Y",
      },
      109922: {
        id: 109922,
        startno: 1003,
        firstName: "Mihiret",
        lastName: "Gebreyowhans",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1997-09-11",
        teamName: "Ethiopia",
        teamId: "Ethiopia",
        stillInTheRace: "Y",
      },
      89937: {
        id: 89937,
        startno: 136,
        firstName: "Omer",
        lastName: "Shapira",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-09-09",
        teamName: "Israel",
        teamId: "Israel",
        stillInTheRace: "Y",
      },
      174196: {
        id: 174196,
        startno: 1001,
        firstName: "Kanza",
        lastName: "Malik",
        nationCode: "PAK",
        nationName: "Pakistan",
        birthDate: "1986-12-22",
        teamName: "Pakistan",
        teamId: "Pakistan",
        stillInTheRace: "Y",
      },
      96746: {
        id: 96746,
        startno: 104,
        firstName: "Julia",
        lastName: "Borgstr\u00f6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      104660: {
        id: 104660,
        startno: 111,
        firstName: "Erika Milena",
        lastName: "Botero Lopez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-01-07",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      90106: {
        id: 90106,
        startno: 72,
        firstName: "Evita",
        lastName: "Muzic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-05-26",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 15,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 66,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      102007: {
        id: 102007,
        startno: 120,
        firstName: "Julia",
        lastName: "Biryukova",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-01-24",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      89425: {
        id: 89425,
        startno: 42,
        firstName: "Jessica",
        lastName: "Allen",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-04-17",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      99919: {
        id: 99919,
        startno: 134,
        firstName: "Jarmila",
        lastName: "Machacova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1986-01-09",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      89839: {
        id: 89839,
        startno: 25,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      96735: {
        id: 96735,
        startno: 141,
        firstName: "Lina",
        lastName: "Svarinska",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "2001-03-15",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 58,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      89711: {
        id: 89711,
        startno: 40,
        firstName: "Tayler",
        lastName: "Wiles",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-07-19",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89976: {
        id: 89976,
        startno: 100,
        firstName: "Sarah",
        lastName: "Rijkes",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-02",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      95933: {
        id: 95933,
        startno: 163,
        firstName: "Bisrat",
        lastName: "Ghebremeskel",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1998-08-27",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      107834: {
        id: 107834,
        startno: 130,
        firstName: "Blanka",
        lastName: "Vas",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "2001-09-03",
        teamName: "Hungary",
        teamId: "Hungary",
        stillInTheRace: "Y",
      },
      89412: {
        id: 89412,
        startno: 3,
        firstName: "Amy",
        lastName: "Pieters",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-06-01",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 85,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      89509: {
        id: 89509,
        startno: 93,
        firstName: "Spela",
        lastName: "Kern",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-24",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      89431: {
        id: 89431,
        startno: 46,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      90113: {
        id: 90113,
        startno: 52,
        firstName: "Jolien",
        lastName: "D'Hoore",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-03-14",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      89514: {
        id: 89514,
        startno: 83,
        firstName: "Lourdes",
        lastName: "Oyarbide",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-04-08",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      104500: {
        id: 104500,
        startno: 148,
        firstName: "Agusta Edda",
        lastName: "Bjornsdottir",
        nationCode: "ISL",
        nationName: "Iceland",
        birthDate: "1977-03-15",
        teamName: "Iceland",
        teamId: "Iceland",
        stillInTheRace: "Y",
      },
      89725: {
        id: 89725,
        startno: 96,
        firstName: "Michaela",
        lastName: "Drummond",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-04-05",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 34,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      89488: {
        id: 89488,
        startno: 108,
        firstName: "Alison",
        lastName: "Jackson",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-12-14",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      89736: {
        id: 89736,
        startno: 68,
        firstName: "Audrey",
        lastName: "Cordon-Ragot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-22",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 11,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      98999: {
        id: 98999,
        startno: 62,
        firstName: "Karolina",
        lastName: "Karasiewicz",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1992-07-23",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      89855: {
        id: 89855,
        startno: 106,
        firstName: "Sara",
        lastName: "Penton",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1988-11-15",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 114,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      96817: {
        id: 96817,
        startno: 75,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      169915: {
        id: 169915,
        startno: 1000,
        firstName: "Mercy",
        lastName: "Fwande",
        nationCode: "KEN",
        nationName: "Kenya",
        birthDate: "1993-11-11",
        teamName: "Kenya",
        teamId: "Kenya",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 110,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      132707: {
        id: 132707,
        startno: 36,
        firstName: "Kristen",
        lastName: "Faulkner",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-12-18",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      89446: {
        id: 89446,
        startno: 45,
        firstName: "Chloe",
        lastName: "Hosking",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-10-01",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      144631: {
        id: 144631,
        startno: 18,
        firstName: "Marita",
        lastName: "Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1991-07-06",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      110027: {
        id: 110027,
        startno: 27,
        firstName: "Franziska",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-07-13",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      96795: {
        id: 96795,
        startno: 116,
        firstName: "Nina",
        lastName: "Berton",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "2001-08-03",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "World Champ Women"
  const raceID = 33

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
